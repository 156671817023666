import axios from "axios"
import { API_ENDPOINTS, AppSettings, env } from "../configs/appConfig"

import { isUserLoggedIn } from "../services/authService"
import storageService from "../services/storageService"

import { store } from "../redux/store"
import { handleLogout } from "../redux/authentication"
import toastService from "../services/toastService"
import { t } from "i18next"

export const config = process.env.REACT_APP_ENV === "prod" ? env.prod : env.dev
const http = axios.create({
  baseURL: config.API_URL
})

http.interceptors.request.use(
  (request) => {
    if (
      isUserLoggedIn &&
      request.url !== API_ENDPOINTS.REGISTER &&
      request.url !== API_ENDPOINTS.LOGIN &&
      request.url !== API_ENDPOINTS.RESET_PASSWORD
    ) {
      const accessToken = storageService.getToken()
      request.headers.Authorization = `${AppSettings.tokenType} ${accessToken}`
    }
    return request
  },
  (error) => {
    Promise.reject(error)
  }
)

http.interceptors.response.use(
  (response) => {
    return Promise.resolve(response)
  },
  async (error) => {
    //auth error
    if (error.response.status === 401) {
      const request_config = error.config
      //validate if user is loggedIn in order to avoid refresh token on anonimous user

      if (isUserLoggedIn()) {
        //make a new instance
        const httpRefresh = axios.create({
          baseURL: config.API_URL
        })

        const data = { refresh: storageService.getRefreshToken() }
        try {
          const response = await httpRefresh.post(
            API_ENDPOINTS.REFRESH_TOKEN,
            data
          )
          storageService.setToken(response.data.access)
          //continue with the pending request
          return http(request_config)
        } catch (e) {
          toastService.showError(t("sessionExpired"))
          //fail to refresh token so back to login page
          store.dispatch(handleLogout())

          //back error to the caller
          return Promise.reject(error)
        }
      }
    }
    if (error.response.status === 400) {
      if (error.response.config.url === API_ENDPOINTS.LOGIN) {
        //@TODO: Darien no entiendo esto aquí
        if (isUserLoggedIn()) {
          storageService.clearStorage()
        }
        //TODO Handle error message
        return Promise.reject(error)
      }
    }
    if (error.response.status >= 500) {
      toastService.showError(t("serverError"))
    }
    return Promise.reject(error)
  }
)

export default http
