import { AppSettings } from "../configs/appConfig"
// ** Redux Imports
import storageService from "../services/storageService"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import authService from "../services/authService"
import getAbilitiesByRole from "../utility/loadActionsByRole"

const config = AppSettings

// First, create the thunk
export const logoutThunk = createAsyncThunk("account/logout", async () => {
  try {
    await authService.logout()

    return true
  } catch (error) {
    //ignore server error for this particular case
    return true
  }
})

const initialUser = () => {
  const item = storageService.getUserData()
  //** Parse stored json or if none return initialValue
  return item ? item : {}
}

export const authSlice = createSlice({
  name: "authentication",

  initialState: {
    fetchingUserData: true,
    userData: undefined,
    isUserLoggedIn: false
  },

  reducers: {
    fetchUserData: (state) => {
      const initialData = initialUser()
      state.userData = initialData
      state.fetchingUserData = false
      state.isUserLoggedIn = Object.keys(initialData).length > 0
    },

    handleLogin: (state, action) => {
      const userData = {
        ...action.payload,
        ability: getAbilitiesByRole(action.payload.role)
      }
      state.isUserLoggedIn = true
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName]
      storageService.setUserData(userData)
      storageService.setToken(action.payload.accessToken)
      storageService.setRefreshToken(action.payload.refreshToken)
    },
    handleLogout: (state) => {
      state.userData = {}
      state.isUserLoggedIn = false
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      storageService.clearStorage()
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logoutThunk.fulfilled, (state) => {
      state.userData = {}
      state.isUserLoggedIn = false
      state.fetchingUserData = false
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      storageService.clearStorage()
    })
  }
})

export const { handleLogin, handleLogout, fetchUserData } = authSlice.actions

export default authSlice.reducer
