import { AppSettings } from "../configs/appConfig"

const storageService = {
  getToken() {
    return localStorage.getItem(AppSettings.storageTokenKeyName)
  },

  setToken(value) {
    localStorage.setItem(AppSettings.storageTokenKeyName, value)
  },

  getRefreshToken() {
    return localStorage.getItem(AppSettings.storageRefreshTokenKeyName)
  },

  setRefreshToken(value) {
    localStorage.setItem(AppSettings.storageRefreshTokenKeyName, value)
  },

  getUserData() {
    return JSON.parse(localStorage.getItem(AppSettings.storageUserDataKeyName))
  },

  setUserData(value) {
    localStorage.setItem(
      AppSettings.storageUserDataKeyName,
      JSON.stringify(value)
    )
  },

  clearStorage() {
    localStorage.removeItem(AppSettings.storageTokenKeyName)
    localStorage.removeItem(AppSettings.storageRefreshTokenKeyName)
    localStorage.removeItem(AppSettings.storageUserDataKeyName)
  }
}
export default storageService
