import http from "../utility/http"
import objectExt from "../utility/objectExt"
// class apiServiceV1 {
//   async post() {
//     const result = await http.post()
//     return result
//   }
// }
// export { apiServiceV1 }
export const apiService = {
  get: (url, onResponse, onError, config = null) => {
    http.get(url, config).then(
      (response) => {
        if (objectExt.isNullOrUndefined(onResponse)) return
        if (!objectExt.isFunc(onResponse)) return
        onResponse(response)
      },
      (error) => {
        if (objectExt.isNullOrUndefined(onError)) return
        if (!objectExt.isFunc(onError)) return
        onError(error)
      }
    )
  },

  post: (url, data, onResponse, onError, config = null) => {
    http.post(url, data, config).then(
      (response) => {
        if (objectExt.isNullOrUndefined(onResponse)) return
        if (!objectExt.isFunc(onResponse)) return
        onResponse(response)
      },
      (error) => {
        if (objectExt.isNullOrUndefined(onError)) return
        if (!objectExt.isFunc(onError)) return
        onError(error)
      }
    )
  },

  postAsync: async (url, data) => {
    return await http.post(url, data)
  },

  put: (url, data, onResponse, onError) => {
    http.put(url, data).then(
      (response) => {
        if (objectExt.isNullOrUndefined(onResponse)) return
        if (!objectExt.isFunc(onResponse)) return
        onResponse(response)
      },
      (error) => {
        if (objectExt.isNullOrUndefined(onError)) return
        if (!objectExt.isFunc(onError)) return
        onError(error)
      }
    )
  },

  patch: (url, data, onResponse, onError) => {
    http.patch(url, data).then(
      (response) => {
        if (objectExt.isNullOrUndefined(onResponse)) return
        if (!objectExt.isFunc(onResponse)) return
        onResponse(response)
      },
      (error) => {
        if (objectExt.isNullOrUndefined(onError)) return
        if (!objectExt.isFunc(onError)) return
        onError(error)
      }
    )
  },

  delete: (url, onResponse, onError) => {
    http.delete(url).then(
      (response) => {
        if (objectExt.isNullOrUndefined(onResponse)) return
        if (!objectExt.isFunc(onResponse)) return
        onResponse(response)
      },
      (error) => {
        if (objectExt.isNullOrUndefined(onError)) return
        if (!objectExt.isFunc(onError)) return
        onError(error)
      }
    )
  }
}

export default apiService
