import resourcesAcl from "./resourcesAcl"

const getAbilitiesByRole = (role) => {
  const common = [
    resourcesAcl.dashboard,
    resourcesAcl.profile,
    resourcesAcl.events,
    resourcesAcl.eventDetail
  ]
  const mapOfAbilities = {
    candidate: [
      {
        action: "read",
        subject: "my-jobs"
      },
      ...common
    ],
    business: [
      {
        action: "read",
        subject: "my-candidates"
      },
      ...common
    ],
    admin: [
      resourcesAcl.dashboard,
      resourcesAcl.adminJobs,
      resourcesAcl.adminBusiness,
      resourcesAcl.adminBusinessPayments,
      resourcesAcl.adminBusinessTransferRequest,
      resourcesAcl.adminEvents,
      resourcesAcl.adminNotifyUsers,
      resourcesAcl.adminPromotionPayments,
      resourcesAcl.adminUsers,
      resourcesAcl.adminSettings
    ]
  }
  return mapOfAbilities[role] || null
}

export default getAbilitiesByRole
