const resourcesAcl = {
  dashboard: {
    action: "read",
    subject: "dashboard"
  },
  profile: {
    action: "read",
    subject: "profile"
  },
  events: {
    action: "read",
    subject: "events"
  },
  eventDetail: {
    action: "read",
    subject: "event-detail"
  },
  adminJobs: {
    action: "read",
    subject: "admin-jobs"
  },
  adminEvents: {
    action: "read",
    subject: "admin-events"
  },
  adminUsers: {
    action: "read",
    subject: "admin-users"
  },
  adminBusiness: {
    action: "read",
    subject: "admin-business"
  },
  adminPromotionPayments: {
    action: "read",
    subject: "admin-promotion-payments"
  },
  adminBusinessTransferRequest: {
    action: "read",
    subject: "admin-business-transfer-request"
  },
  adminNotifyUsers: {
    action: "read",
    subject: "admin-notify-users"
  },

  adminBusinessPayments: {
    action: "read",
    subject: "admin-business-payments"
  },
  adminSettings: {
    action: "read",
    subject: "admin-settings"
  }
}
export default resourcesAcl
