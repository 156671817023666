import { toast } from "react-toastify"

const toastService = {
  showSuccess: (message) =>
    (message === undefined ? toast.success("Success") : toast.success(message)),
  showError: (message) =>
    (message === undefined ? toast.error("Error") : toast.error(message)),
  showWarning: (message) =>
    (message === undefined ? toast.warn("Warning") : toast.warn(message))
}
export default toastService
