import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import http from "../utility/http"
import { API_ENDPOINTS } from "../configs/appConfig"
import objectExt from "../utility/objectExt"

export const getJobTypes = createAsyncThunk("global/getJobTypes", async () => {
  const response = await http.get(API_ENDPOINTS.JOB_TYPES)
  return response.data
})
export const getZones = createAsyncThunk("global/getZones", async () => {
  const response = await http.get(API_ENDPOINTS.ZONES)
  return response.data
})

export const getLanguages = createAsyncThunk(
  "global/getLanguages",
  async () => {
    const response = await http.get(API_ENDPOINTS.LANGUAGES)
    return response.data
  }
)

export const getBusinessCandidates = createAsyncThunk(
  "global/getBusinessCandidates",
  async () => {
    const response = await http.get(
      API_ENDPOINTS.BUSINESS_CANDIDATES_MEMBERSHIPS
    )
    return response.data
  }
)

export const getTags = createAsyncThunk("global/getTags", async () => {
  const response = await http.get(API_ENDPOINTS.ADMIN_TAGS)
  return response.data
})

export const addBusinessCandidate = createAsyncThunk(
  "global/addBusinessCandidates",
  async (candidateId) => {
    const url = `${API_ENDPOINTS.BUSINESS_CANDIDATES_MEMBERSHIPS}${candidateId}/`
    const { data } = await http.get(url)
    return data
  }
)

export const globalSlice = createSlice({
  name: "global",
  initialState: {
    loading: false,
    job_types: [],
    zones: [],
    languages: [],
    businessCandidates: undefined
  },
  reducers: {
    handleLoading: (state, action) => {
      state.loading = action.payload
    },
    handleRemoveBusinessCandidates: (state, action) => {
      state.businessCandidates.splice(action.payload, 1)
    },
    handleUpdateBusinessCandidates: (state, action) => {
      if (
        state.businessCandidates !== undefined &&
        objectExt.isArray(state.businessCandidates)
      ) {
        const index = state.businessCandidates.findIndex(
          (x) => x.id === action.payload.id
        )
        if (index > -1) {
          state.businessCandidates[index].job_types =
            action.payload?.extra_data?.job_types ||
            state.businessCandidates[index].job_types
        }
      }
    },
    clearBusinessCandidates: (state) => {
      state.businessCandidates = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getJobTypes.fulfilled, (state, action) => {
      state.job_types = action.payload
    })
    builder.addCase(getZones.fulfilled, (state, action) => {
      state.zones = action.payload
    })
    builder.addCase(getLanguages.fulfilled, (state, action) => {
      state.languages = action.payload
    })
    builder.addCase(getBusinessCandidates.fulfilled, (state, action) => {
      state.businessCandidates = action.payload
    })
    builder.addCase(addBusinessCandidate.fulfilled, (state, action) => {
      state.businessCandidates.push(action.payload)
    })
    builder.addCase(getTags.fulfilled, (state, action) => {
      state.tags = action.payload
    })
  }
})

export const {
  handleLoading,
  handleRemoveBusinessCandidates,
  handleUpdateBusinessCandidates,
  clearBusinessCandidates
} = globalSlice.actions

export default globalSlice.reducer
