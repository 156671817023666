const objectExt = {
  isNullOrUndefined: (object) => {
    return object === undefined || object === null
  },
  isString: (object) => {
    return typeof object === "string"
  },
  isNotStringOrEmpty: (object) => {
    return typeof object !== "string" || object === ""
  },
  isNumber: (object) => {
    return typeof object === "number"
  },
  isFunc: (object) => {
    return typeof object === "function"
  },
  isDefinedFunc: (object) => {
    return !objectExt.IsNullOrUndefined(object) && typeof object === "function"
  },
  isArray: (object) => {
    return Array.isArray(object)
  },
  isNotArrayOrEmpty: (object) => {
    return (
      objectExt.IsNullOrUndefined(object) ||
      !Array.isArray(object) ||
      object.length === 0
    )
  },
  isStringEmpty: (str) => {
    return (
      (typeof str === "string" || str instanceof String) && str.trim() === ""
    )
  }
}

export default objectExt
