import { API_ENDPOINTS, AppSettings } from "../configs/appConfig"
import { apiService } from "./apiService"
import storageService from "./storageService"

const authService = {
  login: (args, onResponse, onError) => {
    apiService.post(
      API_ENDPOINTS.LOGIN,
      args,
      (response) => {
        onResponse(response)
      },
      (error) => {
        onError(error)
      }
    )
  },

  // refresh: (http, onResponse, onError) => {
  //   const refreshToken = { refresh: storageService.getRefreshToken() }
  //   if (!objectExt.isNullOrUndefined(refreshToken)) {
  //     // apiService.post(
  //     //   API_ENDPOINTS.REFRESH_TOKEN,
  //     //   refreshToken,
  //     //   (response) => {
  //     //     onResponse(response)
  //     //   },
  //     //   (error) => {
  //     //     onError(error)
  //     //   }
  //     // )
  //   }
  // },

  logout: async () => {
    const data = { refresh: storageService.getRefreshToken() }
    return await apiService.postAsync(API_ENDPOINTS.LOGOUT, data)
  },
  isUserLoggedIn: () => {
    return !!(
      localStorage.getItem(AppSettings.storageUserDataKeyName) &&
      localStorage.getItem(AppSettings.storageTokenKeyName)
    )
  },

  register: (data, onResponse, onError) => {
    apiService.post(
      API_ENDPOINTS.REGISTER,
      { ...data },
      (response) => {
        onResponse(response)
      },
      (error) => {
        onError(error)
      }
    )
  },

  validateCredentials: (data, onResponse, onError) => {
    apiService.post(
      API_ENDPOINTS.VALIDATE_CREDENTIALS,
      data,
      (response) => {
        onResponse(response)
      },
      (error) => {
        onError(error)
      }
    )
  }
  // registerCandidate: (data, onResponse, onError) => {
  //   apiService.post(
  //     ApiEndPoints.REGISTER,
  //     { ...data },
  //     (response) => {
  //       onResponse(response)
  //     },
  //     (error) => {
  //       onError(error)
  //     }
  //   )
  // }
}
export const isUserLoggedIn = () => authService.isUserLoggedIn()
export default authService
