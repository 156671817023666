// ** React Imports
import { Suspense, lazy } from "react"
import ReactDOM from "react-dom"

// ** Redux Imports
import { Provider } from "react-redux"
import { store } from "./redux/store"

// ** Intl & ThemeColors Context
import { ToastContainer } from "react-toastify"
import { ThemeContext } from "./utility/context/ThemeColors"

// ** Fake Database
import "./@fake-db"

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx.min"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"

import "./@core/scss/core.scss"
import "./assets/scss/style.scss"

// ** Geira icons
import "@geira/iconfont/dist/geira-icons.scss"

// ** Flackpickr style
import "../node_modules/flatpickr/dist/flatpickr.min.css"

// ** Service Worker
import * as serviceWorker from "./serviceWorker"
// eslint-disable-next-line no-unused-vars
import "./configs/i18n"

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { AbilityContext } from "./utility/context/Can"
import ability from "./configs/acl/ability"

// eslint-disable-next-line no-unused-vars
//move the key to the server
const stripePromise = loadStripe(
  "pk_test_51K7yxyBQp4224vDiveRfKBzMwED6yddHgsQ5jRpmKK1MxEDfMZpTtdRvfC7qt1pHQZvh1Hgz4jmnILGA0Lkoj9oo00WboyUGjQ"
)
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <Elements stripe={stripePromise}>
            <LazyApp />
          </Elements>
          <ToastContainer newestOnTop />
        </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
