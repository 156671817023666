// ** Reducers Imports
import navbar from "./navbar"
import layout from "./layout"
import auth from "./authentication"
import global from "./global"
import invoice from "./../pages/candidate/store/index"
const rootReducer = {
  global,
  auth,
  navbar,
  layout,
  invoice
}

export default rootReducer
