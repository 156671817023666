export const env = {
  prod: {
    API_URL: "https://onestepjobwebapi-jq6ps.ondigitalocean.app"
    //API_URL: "https://onestepjob-prod-kkrxm.ondigitalocean.app"
  },
  dev: {
    API_URL: "http://localhost:8000"
    //API_URL: "https://onestepjob-prod-kkrxm.ondigitalocean.app"
  }
}

export const API_ENDPOINTS = {
  COUNTRIES: "countries",
  JOB_TYPES: "job-types/",
  ZONES: "zones/",
  LANGUAGES: "languages/",
  LOGIN: "auth/login/",
  LOGOUT: "auth/logout/",
  REFRESH_TOKEN: "auth/token/refresh/",
  REGISTER: "auth/registration/",
  RESET_PASSWORD: "auth/password/reset/",
  RESEND_EMAIL_VERIFICATION: "auth/resend-email/",
  SEARCH_CANDIDATES: "search-candidate",
  PROFILE: "me/user/profile/",
  PROVINCES: "provinces",
  CITIES: "cities",
  BUSINESS_LOGO: "me/business-logo/",
  CANDIDATE_LOGO: "me/candidate-logo/",
  CANDIDATE_ACTIVE_JOB_SEARCH: "me/active-job-search",
  CANDIDATE_JOB_SEARCH_AVAILABILITY: "me/job-search-availability",
  CANDIDATE_VIEWS: "me/views-stats",
  CANDIDATE_BUSINESS_BLACK_LIST: "me/business-blacklist/",
  CANDIDATE_JOB_TYPES: "me/job-types/",
  CANDIDATE_JOB_TYPES_ANSWER: "me/job-types-answers-membership/",
  CANDIDATE_JOB_TYPES_CONTRACT: "me/job-types-contracts-membership/",
  CANDIDATE_JOB_TYPES_ZONE: "me/job-types-zones-membership/",
  CANDIDATE_LANGUAGE: "me/languages-membership/",
  BUSINESS_CANDIDATES_MEMBERSHIPS: "me/candidates/",
  BUSINESS_CANDIDATE_DETAILS: "/me/candidates/download/info/$candidateId",
  BUSINESS_CANDIDATE_JOBTYPES_MEMBERSHIP:
    "/me/candidates/jobs-types/info/$membershipId",
  BUSINESS_CANDIDATE_UPDATE_STATUS:
    "/me/business-candidates-membership/$membershipId/",
  BUSINESS_CANDIDATE_DOWNLOAD_PROFILE: "/me/candidates/download/$candidateId",
  BUSINESS_CANDIDATE_RATING: "me/business-rating/",
  HISTORICAL_RATING: "me/candidate-rating-stats/$candidateId",
  FORMATIONS: "me/formations/",
  WORK_EXPERIENCE: "me/work-experiences/",
  CURRICULUMS: "me/curriculums/",
  EVENTS: "events/",
  CONTACT: "contact-me",
  BUY_CREDITS: "/buy-credits",
  CREDITS_SETTING: "/settings-credit",
  VALIDATE_CREDENTIALS: "accounts/validate-account-credentials",
  HISTORICAL_CREDITS: "me/credits-historical/",
  DOWNLOAD_INVOICE: "me/credits/invoice",
  FIND_BUSINESS: "me/find-business",
  ADMIN_JOB_TYPE: "administration/job-type/",
  ADMIN_QUESTION: "administration/question/",
  ADMIN_EVENT: "administration/event/",
  ADMIN_EVENT_ADD_ORDER_FREE: "administration/user-event-order-free",
  ADMIN_EVENT_GET_ATTENDANTS: "administration/event-attendants/$eventId",
  ADMIN_EVENT_FILE: "administration/event-file/",
  ADMIN_GET_CANDIDATES: "administration/candidates",
  ADMIN_GET_BUSINESS_CANDIDATES: "administration/business-candidates",
  ADMIN_SEARCH_CANDIDATES: "administration/filter/candidates",
  ADMIN_GET_BUSINESS: "administration/business",
  ADMIN_ADD_BUSINESS_CREDITS: "administration/business-credits/$businessId/",
  ADMIN_GET_CANDIDATE_PROMOTION_ORDERS: "administration/promotion-orders",
  ADMIN_GET_BUSINESS_REQUEST_TRANSACTION:
    "administration/business-request-transaction/",
  ADMIN_NOTIFY_GROUPS_OF_USERS: "administration/notify-users",
  ADMIN_USERS: "administration/users/",
  ADMIN_BUSINESS_ORDERS: "administration/business-order",
  ADMIN_SETTINGS: "administration/settings",
  ADMIN_JOB_TYPES_ANSWER: "administration/users/job-types-answers/",
  ADMIN_JOB_TYPES_ZONE: "administration/users/job-types-zones/",
  ADMIN_JOB_TYPES_CONTRACT: "administration/users/job-types-contracts/",
  ADMIN_JOB_TYPES_TAG: "administration/job-tag",
  ADMIN_EVENT_STARTED: "administration/events/status",
  ADMIN_UPDATE_EVENT_STATUS: "administration/events/status/update",
  ADMIN_UPDATE_EVENT_ATTENDANT: "administration/events/attendance",
  ADMIN_REGISTER_USER: "administration/register-user"
}

export const AppSettings = {
  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",
  storageUserDataKeyName: "userData",
  currency: "EUR",
  paymentMethods: {
    card: "card",
    bankTransfer: "bankTransfer"
  }
}
